<template>

	<div class="grade" :style="{direction: yuyan.now=='hanyu'?	'ltr':'rtl'}">

		<div class="cards">
<!-- 			<el-row class="susu-input">
				<el-col :span="24">
					<el-input :placeholder="yuyan.input" v-model="input" clearable>
					</el-input>
				</el-col>
			</el-row> -->
			<el-card shadow="hover" :body-style="card">
				<el-table :header-cell-style="headClass" :data="score_data.records" border stripe>
					<el-table-column align="center" resizable prop="title" :label="yuyan.grade_name"></el-table-column>
					<el-table-column align="center" resizable prop="tryCount" :label="yuyan.grade_numb">
					</el-table-column>
					<el-table-column align="center" resizable prop="maxScore" :label="yuyan.grade_score">
					</el-table-column>
					<el-table-column :formatter="gradeIspass" align="center" resizable prop="passed"
						:label="yuyan.grade_ispass"></el-table-column>
					<el-table-column align="center" resizable prop="updateTime" :label="yuyan.grade_last_time">
					</el-table-column>
					<el-table-column align="center" resizable prop="" :label="yuyan.grade_play">
						<template #default="scope">
							<el-button size="mini" icon="el-icon-view" @click="xiangQing(scope.row)" style="margin-right: 10px;">
								{{yuyan.grade_list}}</el-button>
							<el-button size="mini" icon="el-icon-close" type="danger" style="margin-right: 10px;"
								@click="handlerExamBook(scope.row.examId)">{{yuyan.grade_error}}</el-button>
						</template>
					</el-table-column>
				</el-table>

				<pagination :total="score_data.total" :page.sync="yema.current" :limit.sync="yema.size"
					@pagination="getExamData" />
			</el-card>

			<!-- 	<el-pagination background layout="prev, pager, next" :total="score_data.length"
				:page-size="score_data.length">
			</el-pagination> -->
			<!-- v-show="dataList.total>0" -->

		</div>

		<el-dialog :title="yuyan.lianxi_detail" v-model="dialogVisible" width="60%">

			<div class="el-dialog-div">
				<grade :paperList="paperList" />
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination'
	import grade from "./paper.vue"
	import {
		getExamData,
		getXiangQData
	} from "@/api/result.js"
	export default {
		data() {
			return {
				yema: {
					current: 1,
					size: 10,
					params: {},
					t: 0
				},
				input: '',
				yuyan: {},
				dialogVisible: false,
				card: {
					width: '95%',
					backgroundColor: '#fff',
					margin: '0 auto'
				},
				score_data: [],
				//详情页面数据
				paperList: []
			}
		},

		components: {
			grade,
			Pagination
		},
		methods: {
			gradeIspass(row, column) {
				if (row.state === true) {
					return '通过'
				} else {
					return '未通过'
				}
			},
			// 详情
			xiangQing(scope) {
				this.dialogVisible = true
				this.getXiangQData(scope)
			},
			// 错题
			handlerExamBook(examId) {
				this.$router.push({
					path: 'cuoti',
					query: {
						examId: examId
					}
				})
			},
			headClass() {
				let css = 'background-color:#eef1f6'
				return css
			},
			// 获取考试列表
			getExamData() {
				getExamData(this.yema).then(res => {
					this.score_data = res
				})
			},
			// 获取考试详情
			getXiangQData(datas) {
				let data = {
					// 错点current不知道是啥
					current: this.score_data.current,
					params: {
						userId: datas.userId,
						examId: datas.examId
					},
					size: 5
				}
				getXiangQData(data).then(res => {
					this.paperList = res.records
				})
			}

		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			},
			input(to, from) {
				// 搜索
				this.yema.params.title = to
				getExamData(this.yema).then(res => {
					this.score_data = res
				})
			}
		},
		created() {
			this.yuyan = this.$language()
			this.getExamData()
		},
	}
</script>

<style lang="scss" scoped>
	.grade {
		overflow: hidden;
		// background-color: #dddddd;
		height: 100%;
		width: 100%;

		.susu-input {
			margin-top: 20px;
			margin-left: 20px;
			width: 20%;
		}

		.cards {
			height: 100%;
			overflow-y: scroll;
			margin: 0 auto;

			.el-card {
				margin: 20px;
			}
		}

		.el-pagination {
			margin-bottom: 20px;
		}

		.el-dialog-div {
			height: 60vh;
			overflow: auto;
			padding: 10px;
		}
	}
</style>
